import { DecodedToken } from "@/models/constant/interface/common.interface";
import Store from "@/store";
import jwtDecode from "jwt-decode";

export function settingCookies(
  name: string,
  value: any,
  expireDays: number,
  path = "/"
): void {
  let cookieStr = `${name}=${value};`;
  if (expireDays) {
    const dtExpires = new Date(
      new Date().getTime() + expireDays * 1000 * 60 * 60 * 24
    );
    cookieStr += `expires=${dtExpires.toUTCString()};`;
  }
  if (path) cookieStr += `path=${path};`;
  document.cookie = cookieStr;
}

export function removeCookies(name: string): void {
  document.cookie = `${name}=;`;
}

export function isAccessTokenExpire(): boolean {
  const refresh_token = Store.state.refresh_token;
  if (refresh_token) {
    const decoded: DecodedToken = jwtDecode(refresh_token);
    const { exp } = decoded;
    return exp < Date.now() / 1000;
  }
  return false;
}

export function getauthorities(): any {
  const access_token = Store.state.access_token;
  const tempData = [] as any[];
  if (access_token) {
    const decoded: DecodedToken = jwtDecode(access_token);
    decoded.authorities.forEach(item => {
      const obj = {
        name: item.split(":")[0],
        value: [item.split(":")[1]],
      };
      if (tempData.length < 1) {
        tempData.push(obj);
      } else {
        if (tempData.findIndex(data => data.name === obj.name) === -1) {
          tempData.push(obj);
        } else {
          tempData[
            tempData.findIndex(data => data.name === obj.name)
          ].value.push(obj.value[0]);
        }
      }
    });
    tempData?.forEach(item => {
      const tempRoutes = [] as any[];
      const tempActions = [] as any[];
      for (const data of item.value) {
        const routesPush = "/" + item.name + "/" + data;
        tempRoutes.push(routesPush);
      }
      item["routes"] = tempRoutes;
      for (const data2 of item.value) {
        if (data2 !== "create" && data2 !== "read") {
          tempActions.push(data2);
        }
      }
      item["actions"] = tempActions;
    });
    return tempData;
  }
}

export function searchTree(element: any, matchingTitle: any) {
  if (element.identity == matchingTitle) {
    return element;
  } else if (element.children != null) {
    let i: any;
    let result = null;
    for (i = 0; result == null && i < element.children.length; i++) {
      result = searchTree(element.children[i], matchingTitle);
    }
    return result;
  }
  return null;
}
