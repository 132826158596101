var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("a-table", {
    attrs: {
      size: "small",
      dataSource: _vm.dataSource,
      columns: _vm.columnsTable,
      "row-key": "id",
      scroll: { x: 3000 },
      "row-class-name": function(_record, index) {
        return index % 2 ? "bg-white" : "bg-gray-light"
      }
    },
    scopedSlots: _vm._u([
      {
        key: "journalNumbers",
        fn: function(_, record) {
          return [
            _vm._l(record.journalObjects, function(journal) {
              return [
                _c(
                  "div",
                  { key: journal.id },
                  [
                    _c(
                      "RouterLink",
                      {
                        attrs: {
                          to: {
                            name: "generaljournal.detail",
                            params: {
                              id: journal.id
                            }
                          },
                          target: "_blank"
                        }
                      },
                      [_vm._v(" " + _vm._s(journal.number) + " ")]
                    )
                  ],
                  1
                )
              ]
            }),
            !record.journalObjects.length
              ? _c("span", [_vm._v(_vm._s("-"))])
              : _vm._e()
          ]
        }
      },
      {
        key: "navigate",
        fn: function(text, record) {
          return [
            _c(
              "a-tooltip",
              { attrs: { title: text } },
              [
                _c(
                  "a-button",
                  {
                    attrs: { type: "link" },
                    on: {
                      click: function($event) {
                        return _vm.navigateByTransactionType(record)
                      }
                    }
                  },
                  [_vm._v(" " + _vm._s(text) + " ")]
                )
              ],
              1
            )
          ]
        }
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }