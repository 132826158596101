







































import { SearchBuilder } from "@/builder";
import dateFormat from "@/filters/date.filter";
import { toTitlecase } from "@/helpers/common";
import { RequestQueryParams } from "@/models/class/request-query-params.class";
import { DATE_FORMAT_DD_MM_YYYY_HH_MM_SS } from "@/models/constants/date.constant";
import {
  ResponseAssetHistory,
  ResponseAssetHistoryTransactionDetail,
} from "@/models/interface/asset-history.interface";
import {
  AssetHistories,
  ResponseGetDetailInquiry,
} from "@/models/interface/assets.interface";
import { assetAdjustmentService } from "@/services/asset-adjustment.service";
import { assetsServices } from "@/services/assets.service";
import { StringUtils } from "@/utils";
import Vue, { PropType } from "vue";

interface Journal {
  id: string;
  number: string;
}

interface Row extends ResponseAssetHistory {
  transactionDetailString: string;
  journalObjects: Journal[];
}

export default Vue.extend({
  props: {
    dataAssetHistory: {
      type: Object as PropType<ResponseGetDetailInquiry>,
    },
    currentTab: String,
  },
  data() {
    return {
      dataSource: [] as Row[],
      columnsTable: [
        {
          title: this.$t("lbl_reference_number"),
          dataIndex: "referenceNo",
          key: "referenceNo",
          width: 200,
          ellipsis: true,
          scopedSlots: { customRender: "navigate" },
        },
        {
          title: this.$t("lbl_book_type"),
          dataIndex: "bookType",
          key: "bookType",
          width: 120,
          customRender: (text: string) => (text ? toTitlecase(text) : "-"),
        },
        {
          title: this.$t("lbl_transaction_type"),
          dataIndex: "transactionType",
          key: "transactionType",
          scopedSlots: { customRender: "navigate" },
          width: 140,
        },
        {
          title: this.$t("lbl_journal_number"),
          dataIndex: "journalNumbers",
          key: "journalNumbers",
          scopedSlots: { customRender: "journalNumbers" },
          width: 140,
        },
        {
          title: this.$t("lbl_asset_number"),
          dataIndex: "assetNo",
          key: "assetNo",
          width: 140,
          customRender: (text: string) => text || "-",
        },
        {
          title: this.$t("lbl_description"),
          dataIndex: "description",
          key: "description",
          width: 500,
          customRender: (text: string) => text || "-",
        },
        {
          title: this.$t("lbl_transaction_date"),
          dataIndex: "transactionDate",
          key: "transactionDate",
          width: 180,
          customRender: (text: string) =>
            dateFormat(text, DATE_FORMAT_DD_MM_YYYY_HH_MM_SS),
        },
        {
          title: this.$t("lbl_period"),
          dataIndex: "period",
          key: "period",
          width: 120,
          customRender: (text: string) => text || "-",
        },
        {
          title: this.$t("common.detail-text", {
            text: this.$t("lbl_transaction"),
          }),
          dataIndex: "transactionDetailString",
          key: "transactionDetailString",
          width: 250,
          customRender: (text: string) => text || "-",
        },
        {
          title: this.$t("lbl_unit_code_parent"),
          dataIndex: "parentUnitCode",
          key: "parentUnitCode",
          width: 150,
          customRender: (text: string) => text || "-",
        },
        {
          title: this.$t("lbl_unit_code"),
          dataIndex: "unitCode",
          key: "unitCode",
          width: 120,
          customRender: (text: string) => text || "-",
        },
        {
          title: this.$t("lbl_sn"),
          dataIndex: "serialNumber",
          key: "serialNumber",
          width: 150,
          customRender: (text: string) => text || "-",
        },
        {
          title: this.$t("lbl_modified_by"),
          dataIndex: "modifiedBy",
          key: "modifiedBy",
          width: 120,
          customRender: (text: string) => text || "-",
        },
        {
          title: this.$t("lbl_created_date"),
          dataIndex: "createdDate",
          key: "createdDate",
          width: 160,
          customRender: (text: string) =>
            dateFormat(text, DATE_FORMAT_DD_MM_YYYY_HH_MM_SS),
        },
      ],
    };
  },
  methods: {
    async navigateByTransactionType(record: AssetHistories): Promise<void> {
      const builder = new SearchBuilder();

      const params = new RequestQueryParams();
      params.search = builder.push(["referenceNo", record.referenceNo]).build();
      const routeParamsRefNo = encodeURIComponent(record.referenceNo);
      const transactionType = record.transactionType.toUpperCase();

      if (transactionType === "ADDITION") {
        const data = await assetsServices.listAssetAdditions(params);
        if (data.data.length > 0 && routeParamsRefNo) {
          this.$router.push({
            name: "asset.view.additions.single",
            params: {
              id: routeParamsRefNo,
            },
          });
        }
      } else if (transactionType === "ADJUSTMENT") {
        const data = await assetAdjustmentService.detailAssetAdjustment(params);
        if (data.length > 0 && routeParamsRefNo) {
          this.$router.push({
            name: "asset.inquiry.adjustment",
            params: {
              id: routeParamsRefNo,
            },
          });
        }
      } else if (transactionType === "RETIREMENT") {
        const paramsRetirement = new RequestQueryParams();
        paramsRetirement.search = builder
          .push(["documentNumber", record.referenceNo])
          .build();
        paramsRetirement.sorts = "createdDate:desc";

        const data = await assetsServices.detailAssetRetirement(
          "",
          paramsRetirement
        );
        if (data.data.length > 0 && routeParamsRefNo) {
          this.$router.push({
            name: "asset.inquiry.retirement",
            params: {
              id: routeParamsRefNo,
            },
          });
        }
      }
    },
    convertTransactionDetailToString(
      detail: ResponseAssetHistoryTransactionDetail
    ): string {
      const transactionDetail =
        Object.keys(detail.oldData).length > 0
          ? detail.oldData
          : detail.newData;
      return StringUtils.objectToString(
        transactionDetail as Record<string, unknown>,
        ". "
      );
    },
    convertJournalToJournalObjects(journalsString?: string): Journal[] {
      const JOURNALS_DELIMITOR = " || ";
      const JOURNAL_DELIMITOR = ";";
      const JOURNAL_ID_INDEX = 0;
      const JOURNAL_NUMBER_INDEX = 1;

      const journals = journalsString?.split(JOURNALS_DELIMITOR) || [];

      return journals.map(journal => {
        const idAndNumber = journal.split(JOURNAL_DELIMITOR);
        return {
          id: idAndNumber[JOURNAL_ID_INDEX],
          number: idAndNumber[JOURNAL_NUMBER_INDEX],
        };
      });
    },
  },
  watch: {
    dataAssetHistory: {
      immediate: true,
      deep: true,
      handler() {
        const { assetHistories } = this.dataAssetHistory;

        this.dataSource = assetHistories.map(dataMap => {
          dataMap.transactionType = toTitlecase(dataMap.transactionType);

          const transactionDetailString = this.convertTransactionDetailToString(
            dataMap.transactionDetail
          );

          const journalObjects = this.convertJournalToJournalObjects(
            dataMap.journals
          );

          return {
            ...dataMap,
            transactionDetailString,
            journalObjects,
          };
        });
      },
    },
  },
});
